import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

// import Slider from 'react-slick';

// import LazyLoad from 'react-lazyload';

const Slider = React.lazy(() => import('react-slick'));

//import * as THREE from 'three'
//import {gsap, ScrollTrigger} from 'gsap'
//import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
//import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

function detectMob() {
  if (window.innerWidth <= 500 && window.innerHeight <= 920) return 'mobile';
  if (window.innerWidth <= 1024 && window.innerHeight <= 1244) return 'tablet';
  if (window.innerWidth <= 1440 && window.innerHeight <= 1244) return 'laptop_small';
  else return 'laptop_large';
}

const isMobile = detectMob();
const imgFolder = isMobile === 'mobile' ? 'images-mobile' : 'images';

const hi = () => {
  console.log('aoa');
  return true;
};

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('show');
    } else {
      entry.target.classList.remove('show');
    }
  });
});

const Home = () => {
  useEffect(() => {
    console.log({ isMobile: isMobile === 'mobile' });

    hi();
    document.querySelectorAll('.hidden').forEach((el) => observer.observe(el));
  }, []);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  return (
    <>
      <section className='banner-section'>
        <div className='banner-container'>
          <div className='head-icon'>
            <img className='hidden' src='images/logo-icon.webp' alt='icon' />
          </div>
          <div className='banner-cartoon-img'>{/* <img src="images/carton-img1.webp" alt="morph" /> */}</div>
          <div className='banner-content'>
            <div className='banner-logo hidden'>
              <span>
                <img src='images/morph-logo.webp' alt='logo' />
              </span>
            </div>
            <div className='content-detail'>
              <div className='banner-title hidden hidden1'>
                <img src='images/banner-text-bg.webp' alt='morph' />
                <h1> a Web3 phigital brand</h1>
              </div>
              <div className='content-discriptions hidden hidden1'>
                <p>
                  We are a company specialized in verch (virtual merchandising).
                  <br />
                  We build virtual products that become physical one’s.
                </p>
                <p>morph genesis is a NFT collection of 1000 charming creatures that give you access to our ecosystem. </p>
                <p>morph is here to bring back magic into your life.</p>
              </div>
            </div>
          </div>
          <div className='banner-enter-text'>
            <div className='text-hand-title hidden hidden1'>
              <img src='images/text-1.webp' alt='morph' />
            </div>
            <div className='text-hand-image hidden hidden1'>
              <img src='images/hand-img.webp' alt='morph' />
            </div>
          </div>
          <div className='morph-section'>
            {!(isMobile === 'mobile') ? (
              <Row>
                <Col lg={5} md={5}></Col>
                <Col lg={7} md={7}>
                  <img src='images/page2.png' alt='morph' className='hidden' />
                </Col>
              </Row>
            ) : (
              <div>
                <div className='morph-title hidden hidden1'>
                  <img src='images/text-2.webp' alt='morph' />
                </div>
                <div className='morph-circle hidden hidden1'>
                  <img src='images/icon1.webp' alt='images' />
                </div>
                <div className='morph-cards'>
                  <div className='morph-card-holder hidden hidden1'>
                    <div className='card-image'>
                      <img src='images/cartoon-card1.webp' alt='morph' />
                    </div>
                    <div className='card-content'>
                      <div className='card-discriptions'>
                        <p>
                          Owning a morph is the unique way that gives you life-time membership access to The Lab. Being part of it allows you to access
                          exclusive phygital NFT drops in partnership with famous artistic directors.
                        </p>
                      </div>
                      <div className='card-discriptions'>
                        <p>
                          Lab members will get exclusive claims & WL on all drops. Combining virtual and physical versions ​​through a burn system, each piece
                          of art is a unique limited-edition.{' '}
                        </p>
                      </div>
                      <div className='card-discriptions'>
                        <p>
                          Join morph =
                          <br />
                          Join the most stylish Web3 community.
                          <br />
                          <br />
                          “Owning a morph is more than owning a Jpeg, owning a morph is a lifestyle.”
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className='morph-card-holder hidden hidden1'>
                <div className='card-image'>
                  <img src='images/cartoon-card2.webp' alt='morph' />
                </div>
                <div className='card-content'>
                  <div className='card-discriptions'>
                    <p>
                      (Not your regular sh*tty merch) Most people just exist while morphs’ are living (and they’re killing it bro) They like to express
                      themselves by the way they drip. The only way to unlock the closet is by being part of the morpham. So… get ready to be the fashion Web3
                      community !
                    </p>
                  </div>
                </div>
              </div>
              <div className='morph-card-holder hidden hidden1'>
                <div className='card-image'>
                  <img src='images/cartoon-card3.webp' alt='morph' />
                </div>
                <div className='card-content'>
                  <div className='card-discriptions'>
                    <p>
                      Just like you, morphs’ enjoy partying ! Therefore, unforgettable crazy live parties and events will be organized. The lucky ones who will
                      be physically present will not only have fun (a lot of fun) but also meet new people (many people).
                      <b>’Owning a morph is more than owning a Jpeg, owning a morph’ is a lifestyle.”</b>
                    </p>
                  </div>
                </div>
              </div> */}
                </div>
                <div className='banner-bg-cartoon'>
                  {/* <img src='images/carton-img2.webp' alt='img' /> */}
                  {/* <div style={{ width: '1196px', height: '570px' }}></div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className='morph-collection'>
        <div className='collection-container'>
          <div className='morph-collection-bgimg'>
            {/* <img src='images/carton-img3.webp' alt='images' /> */}
            <img src='images/carton-img3-empty.png' alt='images' />
            {/* <div style={{ maxWidth: '1080px', maxHeight: '1089px' }}></div> */}
          </div>
          <div className='morph-collection-detail'>
            {isMobile === 'mobile' ? (
              <img src='images-mobile/ticket-img.png' alt='morph' style={{ scale: '0.8', marginLeft: '30px' }} />
            ) : (
              <>
                <div className='morph-collection-card hidden hidden1'>
                  <img src='images/ticket-img.png' alt='morph' />
                </div>
                <div className='morph-collection-circle hidden hidden1'>
                  <img src='images/circle-img.webp' alt='morph' />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <section className='dimond-section'>
        <div className='container'>
          {/* <LazyLoad height={800}> */}
          <Row style={{ marginBottom: '60px' }}>
            <Col lg={6} md={6}>
              <div className='dimond-video hidden'>
                <img src='images/morph-video.gif' alt='morph' />
                <div className='edouard-title hidden hidden1'>
                  <img src='images/edouard-title.webp' alt='morph' />
                </div>
                <div className='dimond-video-title'>
                  <img src='images/text-4.webp' alt='morph' />
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} style={{ paddingTop: '50px' }}>
              <div className='edouard-section'>
                <div className='edouard-content-slider hidden hidden1'>
                  <div className='edouard-slider'>
                    {/* <LazyLoad height={400}> */}
                    <Slider {...settings}>
                      <div className='slider-image'>
                        <img src='images/slider-img1.webp' alt='celebrity' />
                      </div>
                      <div className='slider-image'>
                        <img src='images/slider-img3.jpeg' alt='celebrity' />
                      </div>
                      <div className='slider-image'>
                        <img src='images/slider-img2.webp' alt='morph' />
                      </div>
                    </Slider>
                    {/* </LazyLoad> */}
                  </div>
                  <div className='edouard-content' style={{ marginTop: '3px' }}>
                    <div className='edouard-content-dis'>
                      <p>
                        <b>The first morph collab</b> <br />
                        created inside The Lab is a unique phygital necklace. Made of real white-gold & diamonds, this piece of art is only made for real Web3
                        passionates.
                      </p>
                      <p>Thanks to the diamondneck you’ll be able to show-off you’ll best nft.</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* </LazyLoad> */}

          <div className='collab-section'>
            <div className='collab-image hidden hidden1'>
              <img src='images/img1.webp' alt='morph' />
            </div>
            <div className='collab-title hidden hidden1'>
              <img src='images/collab-img.webp' alt='collab' />
            </div>
          </div>
        </div>
      </section>
      <section className='toundery-section'>
        <div className='container'>
          <div className='toundery-title hidden hidden1'>
            <img src='images/morph-logo.webp' alt='logo' />
            <div className='toundery-heading'>
              <img src='images/text-5.webp' alt='morph' />
            </div>
            <div className='toundery-content'>
              <p>Nothing would be possible without a great team, here are some members of our board:</p>
            </div>
          </div>
          <div className='toundery-list'>
            <ul>
              <li className='hidden hidden1'>
                <div className='list-detail'>
                  <a href='https://instagram.com/taylorchiche?igshid=YmMyMTA2M2Y=' className='list-img'>
                    <img src='images/toy-img1.webp' alt='morph' />
                  </a>
                  <div className='list-content'>
                    <p>
                      {/* <br /> */}
                      <b>BRAND DIRECTOR</b>
                      <br />
                      <br />
                      Serial entrepreneur and investor with a strong network.
                      <br />
                      <br />
                      Google his name.
                    </p>
                  </div>
                </div>
              </li>
              <li className='hidden hidden1'>
                <div className='list-detail'>
                  <a href='https://instagram.com/iamsilouane?igshid=YmMyMTA2M2Y=' className='list-img'>
                    <img src='images/toy-img2.webp' alt='morph' />
                  </a>
                  <div className='list-content'>
                    <p>
                      <b>CO-FOUNDER</b>
                      <br />
                      <br />
                      After having already created several successful companies in the digital space, passionate about Web3, he launches into the creation of
                      morph.
                    </p>
                  </div>
                </div>
              </li>
              <li className='hidden hidden1'>
                <div className='list-detail'>
                  <a href='https://twitter.com/fomoboyzs?s=21&t=Gu6sdbAx44gBiGB3NaU-PQ' className='list-img'>
                    <img src='images/toy-img3.webp' alt='morph' />
                  </a>
                  <div className='list-content'>
                    <p>
                      <b>Community Leader </b>
                      <br />
                      <br />
                      In the crypto market since 2016, Thomas Salgues is a reference in community building. He is the CEO of Nfinity Labs who worked with many
                      projects such as Blvck Paris or Vitality!
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className='faq-section'>
          <div className='faq-cartoon-img-one hidden hidden1'>
            <img src={imgFolder + '/carton-img4.png'} alt='morph' />
          </div>
          <div className='container hidden hidden1'>
            <div className='faq-title'>
              <img src='images/text-6.webp' alt='images' />
            </div>
            <div className='faq-content'>
              <Accordion flush>
                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='0'>
                    <h3>
                      <Accordion.Header>
                        <span>What is a morph Genesis ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Bridging the gap between the virtual and the physical world, morph is a community-driven Web3 brand centered around collaborations. A
                        morph Genesis will allow you to be part of the project (for life), to be able to claim free exclusive digital assets built by famous
                        artistic directors (that you can burn to obtain physical ones) and… much more that we can’t tell you yet ! #DeliverIsBetterThanPromise
                        Only 1000 Genesis morphs exist ! Small supply = exclusive Community.
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='1'>
                    <h3>
                      <Accordion.Header>
                        <span>What is the morph VISION ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Our Vision is to gather all the most influential and enthusiasts Web3 people from Fashion, Creativity, Art and Technology. To
                        collaborate with the best worldwide artistic directors (we already started) and create very limited editions.
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='2'>
                    <h3>
                      <Accordion.Header>
                        <span>Is the team Doxxed ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Yes we are ! Neither hiding behind a bear-market, nor turning our phones into airplane mode, we are here to last. You can find the main
                        actors of the morph project in the section above this F.A.Q. We also have a team of 20 people working hard on a daily basis to make this
                        project reach its Vision (you’ll meet them soon on our Discord).
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='3'>
                    <h3>
                      <Accordion.Header>
                        <span>How often will there be collaborations ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Multiple times a year, we will announce new morph collabs ! So you mean that by owning a morph Genesis, I'll be able to claim, several
                        times a year, exclusive free digital assets made by worldwide famous artistic directors and that I’ll be able to convert them into
                        physical ones ? YEEESIIIIIRR
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='4'>
                    <h3>
                      <Accordion.Header>
                        <span>What is the first morph collaboration ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        “DIAMOND-NECK” is the first morph collaboration. Created by the famous French jeweler Edouard Nahum (who already made creations for
                        P-Diddy, Madonna, Zidane…), this piece is a real tribute to the NFT universe. You can display any of your NFT (morph Genesis, Bored Ape,
                        Moonbird, Clone X, Azuki…) into it ! All the morph Genesis holders will be able to claim for free the digital asset of the necklace
                        (that you will then be able to burn to get the physical one… more information soon ⏳ ) PS : only real OG will wear that.
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='5'>
                    <h3>
                      <Accordion.Header>
                        <span>Why did you choose to launch your project in a Bear-Market?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        We believe that the best period to build an authentic project is on a Bear Build-Market. Why ? Because then you only reach real
                        enthusiasts who feel connected to the project and will get involved to build strong foundations while staying focused on the true
                        utility and not on the Hype. *And also because morph’s founders have big c*cks (as our friend Vitalik)
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='6'>
                    <h3>
                      <Accordion.Header>
                        <span>How are the 1000 morphs’ Genesis distributed ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        500 morphs NFT will be on private freemint by whales, influencers, and passionates about the project (all reserved), the other 500 will
                        be on a private sale and will give you access to everything (maximum = 2 per wallet).
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='7'>
                    <h3>
                      <Accordion.Header>
                        <span>Which blockchain did you use ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>Is that real a question lmfao ? ETH ofc !</Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='8'>
                    <h3>
                      <Accordion.Header>
                        <span>Will the gas fees be expensive ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        We invested a ton of money these past months in R&D so we can have the smoothest mint possible ! Our team of engineers worked very hard
                        to offer you the best experience with the lowest gas fees.
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='9'>
                    <h3>
                      <Accordion.Header>
                        <span>What are the funds being used for ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Transparency is very important for us. The funds will be used for 4 things :
                        <br />
                        • Collabs / Roadmap
                        <br />
                        • R&D
                        <br />
                        • To pay the team
                        <br />• For a security fund locked on a visible public ledger
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='10'>
                    <h3>
                      <Accordion.Header>
                        <span>Is investing in a morph Genesis a great investment, and if yes, why ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        By investing in this collection, you’ll obtain free limited edition digital assets made by artistic directors (specially for morph),
                        selling their art for tens of thousands of dollars (and millions for some…) so it will for sure have a value on the market. Also, by
                        investing on a solid and concrete NFT project when ETH is near the bottom, will not only make you benefit from the morph utility but
                        also from the morphs’ price growth + the ETH growth !
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>

                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='11'>
                    <h3>
                      <Accordion.Header>
                        <span>Who is wanted into the morph community ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Even if we know that our project can be a great financial asset for you, we don’t want people who will only be there for the financial
                        purpose. We are looking for real NFT enthusiasts who will be part of the journey with us. People who are true Crypto lovers, People who
                        are true Art lovers and more precisely, People who believe that these 2 worlds can merge and should merge..
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>
                <div className='faq-content-detail'>
                  <Accordion.Item className='faq-content-detail' eventKey='12'>
                    <h3>
                      <Accordion.Header>
                        <span>I haven't mint any NFT in a long time, so why should I mint this one ?</span>
                      </Accordion.Header>
                    </h3>

                    <p>
                      <Accordion.Body>
                        Because for once, you won’t throw away your money in another creepy project, but you’ll invest it in a strong one, who communicates with
                        clarity and who might be the next big thing…
                      </Accordion.Body>
                    </p>
                  </Accordion.Item>
                </div>
              </Accordion>
            </div>
          </div>

          <div className='faq-cartoon-img-two hidden hidden1'>
            {/* <LazyLoad height={1600}> */}
            <img src='images/carton-img5.webp' alt='morph' />
            {/* </LazyLoad> */}
          </div>

          <div className='footer-content'>
            <div className='footer-icon'>
              {/* <LazyLoad> */}
              <img src='images/footer-logo.webp' alt='morph' />
              {/* </LazyLoad> */}
            </div>
            <ul>
              <li>
                <a href='https://twitter.com/morph_genesis?s=21&t=wTKfiycBB_l3ydbAflBp7A'>
                  <img src='images/twitter-icon.webp' alt='icon' />
                </a>
              </li>
              <li>
                <a href='https://discord.gg/morph'>
                  <img src='images/discord.svg' alt='icon' style={{ width: '39px' }} />
                </a>
              </li>
              {/* <li>
                <a href='instagram.com'>
                  <img src='images/instagram-icon.webp' alt='icon' />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
