import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connectWallet, purchaseMorphs, purchaseMorphsWhitelist } from '../eth-setup/apis';

import { useTimer } from 'react-timer-hook';

function detectMob() {
  if (window.innerWidth <= 500 && window.innerHeight <= 920) return 'mobile';
  if (window.innerWidth <= 1024 && window.innerHeight <= 1244) return 'tablet';
  if (window.innerWidth <= 1440 && window.innerHeight <= 1244) return 'laptop_small';
  else return 'laptop_large';
}

// comment

const isMobile = detectMob();

// Get Guidance From Front End Experts, About this code, improvements
const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('show');
    } else {
      entry.target.classList.remove('show');
    }
  });
});

const timerDate = '30 Oct 2022 18:10:00 UTC';
const Timer1 = () => {
  const { seconds, minutes, hours, isRunning } = useTimer({
    expiryTimestamp: Date.parse(timerDate),
    onExpire: () => {},
  });

  return (
    <div className='neonText hidden hidden1' style={{ textAlign: 'center', marginTop: '10px' }}>
      {hours} hrs : {minutes} mins : {seconds} sec
    </div>
  );
};
const Timer2 = () => {
  const { seconds, minutes, hours, isRunning } = useTimer({
    expiryTimestamp: Date.parse(timerDate),
    onExpire: () => {},
  });

  return (
    <div className='neonText' style={{ textAlign: 'center', marginTop: '20px' }}>
      {hours} hrs : {minutes} mins : {seconds} sec
    </div>
  );
};

const Mint = () => {
  const [qty, setQty] = useState(1);
  const [wallet, setWallet] = useState(null);

  const [loader, setLoader] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const setToastText = (text) => {
    setToastMessage(text);
    setTimeout(() => {
      setToastMessage(false);
    }, 4000);
  };

  console.log({ wallet });
  useEffect(() => {
    document.querySelectorAll('.hidden').forEach((el) => observer.observe(el));
  }, []);

  const set = {
    1: () => setQty(2),
    2: () => setQty(3),
    3: () => setQty(1),
  };

  const center = { marginLeft: 'auto', marginRight: 'auto' };

  return isMobile === 'mobile' ? (
    <div style={{ position: 'absolute', backgroundColor: 'rgb(0, 0, 0)', height: '100vh', width: '100vw' }}>
      <img className='hidden hidden1' src='images-mint/Logo.png' alt='morph' style={{ height: '45px', marginTop: '15px', ...center }} />

      {/* <div className='neonText hidden hidden1' style={{ textAlign: 'center', marginTop: '30px' }}>
        MorphList Sale start in
      </div> */}

      <Timer1 className='hidden hidden1' />

      <div className='hidden hidden1' style={{ display: 'flex', flexDirection: 'col', height: '42px', marginTop: '35px' }}>
        <img
          src='images-mint/BTN MINT.png'
          onClick={() => purchaseMorphsWhitelist(setLoader, qty, setToastText, setWallet, setShowMessage)}
          // onClick={() => purchaseMorphs(setLoader, qty, setToastText, setWallet, setShowMessage)}
          alt='morph'
          style={{ marginLeft: 'auto' }}
        />
        <img src={`images-mint/input${qty}.png`} alt='morph' style={{ marginLeft: '20px', marginRight: 'auto' }} onClick={set[qty]} />
        <img src={`images-mint/input2.png`} alt='morph' style={{ display: 'none' }} />
        <img src={`images-mint/input3.png`} alt='morph' style={{ display: 'none' }} />
      </div>
      <img
        src={'images-mint/' + (wallet ? 'BTN CONNECTED.png' : 'BTN CONNECT.png')}
        onClick={() => {
          connectWallet(setWallet);
        }}
        alt='morph'
        className='hidden hidden1'
        style={{ height: '45px', marginTop: '35px', ...center }}
      />

      <img className='hidden hidden1' src='images-mint/morph-mint-min.gif' alt='morph' style={{ width: '70%', marginTop: '35px', ...center }} />
      {/* <center className='hidden hidden1'>
        <video autoPlay loop muted inline='true' width='300px' style={{ marginTop: '35px' }}>
          <source src='images-mint/morph-mint.mp4' type='video/mp4' />
          Morph Image
        </video>
      </center> */}
    </div>
  ) : (
    <div style={{ position: 'absolute', backgroundColor: 'rgb(0, 0, 0)', height: '140vh', width: '100vw' }}>
      <Row style={{ paddingTop: '60px', paddingLeft: '60px', paddingRight: '40px' }}>
        <Row className='hidden hidden1'>
          <Col lg={2} md={3}>
            <img src='images-mint/Logo.png' alt='morph' style={{ height: '45px' }} />
          </Col>

          <Col lg={3} md={1}></Col>

          <Col lg={2} md={3} style={{ display: 'flex', flexDirection: 'col', height: '42px' }}>
            <img
              src='images-mint/BTN MINT.png'
              // onClick={() => purchaseMorphs(setLoader, qty, setToastText, setWallet, setShowMessage)}
              onClick={() => purchaseMorphsWhitelist(setLoader, qty, setToastText, setWallet, setShowMessage)}
              alt='morph'
              // className='hidden hidden1'
            />

            <img src={`images-mint/input${qty}.png`} alt='morph' style={{ marginLeft: '20px' }} onClick={set[qty]} />
            <img src={`images-mint/input2.png`} alt='morph' style={{ display: 'none' }} />
            <img src={`images-mint/input3.png`} alt='morph' style={{ display: 'none' }} />
          </Col>

          <Col lg={3} md={1}></Col>

          <Col lg={2} md={4}>
            <img
              src={'images-mint/' + (wallet ? 'BTN CONNECTED.png' : 'BTN CONNECT.png')}
              onClick={() => {
                connectWallet(setWallet);
              }}
              alt='morph'
            />
          </Col>
        </Row>

        <Row className='hidden hidden1'>
          <Timer2 />
        </Row>

        <Row className='hidden hidden1'>
          {/* <img src='images-mint/morph-mint-min.gif' alt='morph' style={{ width: '50%', marginTop: '35px', ...center }} /> */}
          <center>
            <video autoPlay loop muted inline='true' width='800px' style={{ marginTop: '35px' }}>
              <source src='images-mint/morph-mint.mp4' type='video/mp4' />
              Morph Image
            </video>
          </center>
        </Row>
      </Row>
    </div>
  );
};

export default Mint;
