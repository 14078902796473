// export const requiredChainId = '0x5';
// export const os = 'https://testnets.opensea.io/assets/goerli';
// export const nftAddress = process.env.REACT_APP_NFT_ADDRESS_TESTNET;

export const requiredChainId = '0x1';
export const os = 'https://opensea.io/assets';
export const nftAddress = '0x8071fA30E5B3818369526dcFbf6492cF89E58fF5';

//
//
//
// Same Data for Testnet and Mainnet
export const whitelist = process.env.REACT_APP_WL ? JSON.parse(process.env.REACT_APP_WL) : [];
export const getContractNft = (web3, address = nftAddress) => new web3.eth.Contract(nftAbi, address);
export const nftAbi = JSON.parse(
  '[{"inputs":[{"internalType":"uint256","name":"_qty","type":"uint256"}],"name":"purchaseMorphs","outputs":[],"stateMutability":"payable","type":"function"},{"inputs":[{"internalType":"uint256","name":"_qty","type":"uint256"},{"internalType":"bytes32[]","name":"_proof","type":"bytes32[]"}],"name":"purchaseMorphsWhitelist","outputs":[],"stateMutability":"payable","type":"function"},{"inputs":[],"name":"morphPrice","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"morphPriceWhitelist","outputs":[{"internalType":"uint256","name":"","type":"uint256"}],"stateMutability":"view","type":"function"}]',
);
