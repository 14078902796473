import keccak256 from 'keccak256';
import { MerkleTree } from 'merkletreejs';
import { getContractNft, nftAddress, os, whitelist } from './smart-contracts-config.js';
import { getBlockchainData } from './metamask-connection.js';

const gasFactor = 1;

export const purchaseMorphs = async (setLoading, howMany, setShowMessage, setWallet, setShowNftMintMessage) => {
  if (!howMany || isNaN(howMany) || howMany === 0) {
    setShowMessage('Enter some quantity to Mint');
    return;
  }
  getBlockchainData(async (account, web3) => {
    setWallet(shortAddress(account));

    const contract = getContractNft(web3);

    const pricePublicMint = await contract.methods.morphPrice().call();
    const totalPrice = (Number(pricePublicMint) * howMany).toString();
    // const totalPrice = await contract.methods.getMorphPrice(howMany).call({ from: account });

    console.log({ totalPrice: web3.utils.fromWei('' + totalPrice) + ' ETH' });
    console.log({ howMany });

    // here we use code from twitter post of nft
    const method = contract.methods.purchaseMorphs(howMany);
    let options = {
      from: account,
      gas: '0',
      value: totalPrice,
    };
    try {
      const estimateGasPrice1 = await method.estimateGas(options);
      const estimateGasPrice2 = Math.trunc(gasFactor * estimateGasPrice1);
      options = { ...options, gas: '' + estimateGasPrice2 };
    } catch (e) {
      let msg;
      try {
        console.log(e.message);
        let a = e.message;
        let objStr = a.substring(a.indexOf('{'), a.lastIndexOf('}') + 1);
        // console.log({ objStr });
        msg = JSON.parse(objStr).message || JSON.parse(objStr).originalError.message;
        msg = msg.replace('err: ', '');
        msg = msg.replace('execution reverted: ', '');
      } catch (eiii) {}

      if (!msg || msg === undefined) {
        msg = 'Insufficient funds';
      }
      if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      // else if (msg === 'Sale is not active') msg = 'Sale will start at 10pm UTC';

      setShowMessage(msg);
      alert(msg);
      return;
    }

    try {
      await method
        .send(options)
        .on('transactionHash', (tx) => setLoading(true))
        .on('confirmation', (i, tx) => {
          console.log({ i, i2: i === 1, tx });
          if (i === 1) {
            setLoading(false);
            let tokenId = Number(tx.events['0'].raw.topics[3]);
            setShowNftMintMessage(`${os}/${nftAddress}/${tokenId}`);
            if (window.confirm('See your Morph now on on OpenSea. Refresh the page if morph does not show.'))
              window.location.href = `${os}/${nftAddress}/${tokenId}`;
          }
        });
    } catch (e) {
      setLoading(false);
      if (e.message === 'MetaMask Tx Signature: User denied transaction signature.') {
        // setShowMessage('User denied transaction');
      } else setShowMessage(e.message);
    }
  });
};

export const purchaseMorphsWhitelist = async (setLoading, howMany, setShowMessage, setWallet, setShowNftMintMessage) => {
  if (!howMany || isNaN(howMany) || howMany === 0) {
    setShowMessage('Enter some quantity to Mint');
    return;
  }
  getBlockchainData(async (account, web3) => {
    setWallet(shortAddress(account));

    const contract = getContractNft(web3);

    const pricePublicMint = await contract.methods.morphPriceWhitelist().call();
    const totalPrice = (Number(pricePublicMint) * howMany).toString();

    console.log({ totalPrice: web3.utils.fromWei('' + totalPrice) + ' ETH' });
    console.log({ howMany });

    const whitelist1 = whitelist.map((a) => a.toLowerCase());
    const tree = new MerkleTree(whitelist1, keccak256, {
      hashLeaves: true,
      sortPairs: true,
    });
    console.log({ root: tree.getHexRoot() });

    const leaf = keccak256(account.toLowerCase());
    const proof = tree.getHexProof(leaf);

    const method = contract.methods.purchaseMorphsWhitelist(howMany, proof);
    let options = {
      from: account,
      gas: '0',
      value: totalPrice,
    };
    try {
      const estimateGasPrice1 = await method.estimateGas(options);
      const estimateGasPrice2 = Math.trunc(gasFactor * estimateGasPrice1);
      options = { ...options, gas: '' + estimateGasPrice2 };
    } catch (e) {
      let msg;
      try {
        console.log(e.message);
        let a = e.message;
        let objStr = a.substring(a.indexOf('{'), a.lastIndexOf('}') + 1);
        // console.log({ objStr });
        msg = JSON.parse(objStr).message || JSON.parse(objStr).originalError.message;
        msg = msg.replace('err: ', '');
        msg = msg.replace('execution reverted: ', '');
      } catch (eiii) {}

      if (!msg || msg === undefined) {
        msg = 'Insufficient funds';
      }
      if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      else if (msg === 'MSG TO REPLACE') msg = 'YOUR MSG';
      // else if (msg === 'Sale is not active') msg = 'Sale will start at 10pm UTC';

      setShowMessage(msg);
      alert(msg);
      return;
    }

    try {
      await method
        .send(options)
        .on('transactionHash', (tx) => setLoading(true))
        .on('confirmation', (i, tx) => {
          console.log({ i, i2: i === 1, tx });
          if (i === 1) {
            setLoading(false);
            let tokenId = Number(tx.events['0'].raw.topics[3]);
            setShowNftMintMessage(`${os}/${nftAddress}/${tokenId}`);
            if (window.confirm('See your Morph now on on OpenSea. Refresh the page if morph does not show.'))
              window.location.href = `${os}/${nftAddress}/${tokenId}`;
          }
        });
    } catch (e) {
      setLoading(false);
      if (e.message === 'MetaMask Tx Signature: User denied transaction signature.') {
        // setShowMessage('User denied transaction');
      } else setShowMessage(e.message);
    }
  });
};

export const getMerkleTree = () => {
  return new MerkleTree(
    [
      '0xa442fc397f3adb0bb33e128050082ff886ab4ecb',
      '0xf588e600bfb1be5fc83a5d2ac615f72e2bdbf016',
      '0x98dc67d6e57ed865dc2cd1ee15adfceb324b71a6',
      '0x7242ca848ab6e8a52942e189b0afd5b7410ef5f9',
      '0xe7784faa7cee97bb4a38edd7211b8ac4ce4ae77a',
      '0xf9cac290245a9bcb959ce970ad04f75b4c7c42d0',
      '0x7cbc5b3c246dbbdf245183abc134809553786ab1',
      '0x1495ea2bd672263ffe24c5992521b0c5a1024c05',
      '0xfd017d208cbcaecb9b8729a321ecab7988e6744a',
      '0xc3c214b6a4a47bf1e3fc2966315e61ffc2172ed9',
      '0x5ee99c0b32bee8126bf8c00b610abd4ec59f9a64',
      '0xc91e5b88bff7d832ccadf84121c56ac64cce3117',
      '0xdfc461216e26031c99b6359d7eec57f3933fb201',
      '0x5b3d02dbb48efad40e8b5225bd0113d422d081d3',
      '0xb6d938cb2e929312d00db1ba21399774da927f9b',
      '0xe874d03e59cc1b02c74e5c807c1de70bd2de1d94',
      '0x81d5dfbfb223e87bb23adb80f4673ea2e42fd508',
      '0x85e965b4cac2b112857ecabcf1f8783ab6203a18',
      '0x80a5866735f5d72fc25779dea58a509b944c1956',
      '0x1ba8b4827f8c6b5f86256f021f0b3307f1141116',
      '0x8f174a30d712ee391d531dd03330e851db11ecde',
      '0xb1451440463b01725dec208f04425b55386b55d1',
      '0xdab18436285671fe296a9e909fcee66fe4220335',
      '0xd77e2db48defb3eba942b212c9623bf13b52972c',
      '0x8a02286b68e992c2e184769e589612a2506d0de8',
      '0x473f303b59c90e7d8d59d3dce05d4f829270ff1a',
      '0xce86a89a59b1596b6465ca8f4f280b8405415a7e',
      '0xc2f6b6d0028031a0d291e6c36d91eb575f1eed59',
      '0xed160a36615bd7d655921f6ab9f425a01871835b',
      '0x411be63e586a1688f3edc7a430b1515442717724',
      '0x878a1536fc3c27311cc1e66f1d4c94068be81e41',
      '0xe87226ed1e698dd8ccf68911f8f086dcf377c910',
      '0x4e12fb0185ecefa2aa166e8faf71a700212e3319',
      '0xfb371ac675f043b48173e2cf74e979ec6d7bee22',
      '0xf882d2a201e6e2b763c9d8bc18e5a776dceea37e',
      '0xff657fe233700309ed65d004828c821c4e9228dd',
      '0xb347c73783a2ed7ce5582df08dc11e4f116d1b14',
      '0xc0a97e4ad95fe090e5a04c63b21aa3c35c12c15f',
      '0x56d6e49e6d4b4f740c1d2fea59ba34336a17b050',
      '0x7a0808434d62099394da3d93f1dd4f29f9296b53',
      '0x9fd8785a32829edbf149ba4656b28ab2cd178405',
      '0xdc9b85495ab1df48f8e99b1146855c468ec9cc9e',
      '0x1f5e077db3e592d42db2944110cf271ddefdc2f0',
      '0xa6855d60127143643a66be40357d070507aaa6f1',
      '0x3b3d24598b4f6fc084693a7ebc504963247730cc',
      '0x5cc84a069564a73d27d99e1ab7ec4379e2d83056',
      '0x8e795fbf30df6a919a7bbc767c9f0b437072a064',
      '0x44b9f2c29a583812bd7fbd94492d085f4edf9659',
      '0x5fd8a539c5cb098f6e923946b0015ae746eaaf49',
      '0x9f0727f8238a4fa7dcbdbe72ff7aab32cd3e5a81',
      '0xbdb83b80deafd021637db6aa83c52132768792e0',
      '0x7910ae3991e613fdba3991f48defa9bd020e61ca',
      '0x35b3e25fdc3f5d9b54aa5af35b05338dee6d652a',
      '0x9de445f7259fd58fe83088f50c4df63c286ed677',
      '0xab6945733504116236a9d9b7ae2508253f30e29a',
      '0x837ae0778e02cc5bbd8c08367f9c0ea8fdebeb58',
      '0x0eaf36b8e5bc8df3b2f9bfe15a2d8533e0477d9b',
      '0x5fd40a58ef481abfa23da7d28bcf4ef4d875955e',
      '0xec94f4b478da740b3a7f359a28e5065bd2e13a25',
      '0x54ac5ed05575a0970a2619db883e7531565508be',
      '0x1f35843d6530328bf609a0bb2e64a82f6a32c2e3',
      '0x020c44b82c15fb3486144a575c36c45b5d82528e',
      '0xe95ee4fa3a5c1c574d3a70ff096cf9e27a8c8f2f',
      '0xec04321d7ad158d592066a66e5a77e206f97eb3a',
      '0xa2116f0fe3320f1cd52d2fd04eb600dfda5c484e',
      '0x2bb802aa2458ce7a1d63907f68dcab8233774cf9',
      '0xe25a5873cca6388011162b969aed4865bff090fd',
      '0x235feeed64aafcfcfd2849abb6c22ad0ff80bf3f',
      '0xc8a370d1af6f146c79062d3d7fdb5e06d53fa1ef',
      '0xc7e2f0cafa53b60de96b927d638151dc163ab0a2',
      '0x9d2c8b0830bdbb850b977d39317a4ebf4fa51f30',
      '0xb5b15f5fac185aeefa2d80e5cab4815b096f2d82',
      '0xd44455443e3cc7a7d7895d21ff447d86531456f8',
      '0x529ecd39d732ab260cfdd7ed9d96428bbc2db99c',
      '0xca9893a2251379991cc769555b6d155107547e7d',
      '0xa1c60d41148d43dd57caa97735a7635f3fa95c42',
      '0xcca4164ee2f27f4f7e677211508ebf987f941d4e',
      '0xb0ebda82792fa5b4f29f3c958deea97e6545caa1',
      '0x59ac9e7e8de578e05e8afa207d26593afdfe443e',
      '0x17641c9f23b841bdf8665a4dc8a4efdd09304fdc',
      '0x780600e92af85ae41ef1bb224a245cfe1218c089',
      '0x283c4196206badd4c0caadb5fb39c78746393b5b',
      '0x11c8de5e6698faffd6472ad5cfe784e2019478fa',
      '0x3bbe909aca5183dfd0762338b3ead8971c22ecfd',
      '0x3380dfa79f4dd3f586199ba7ae61379dc0d2088c',
      '0xec7eec8bca8d6f850fee5439ba461d03ec28e6a6',
      '0x902f5c21041df71827ada147f4c5c2bc794745bf',
      '0x51345128613f9a2dbdaed8d1a737969375bfd681',
      '0xa48abda1845d7f1c8a00891c05beeee5d70fbafb',
      '0x6c0b03acae6910c057f9cfeadd0b8a26ef27d79d',
      '0x3c2c23bf3ab48726cfa18863d433d7607afe4836',
      '0x20de8ff3f6c14f63562cbcc4860f30a9e96bd857',
      '0x8f0adaddc5d2b5deb4e42c846a8542bb404c800e',
      '0x40f76a02bb8b113484fef1a34166cba080a9f8b9',
      '0x03d8f0f773b91854dce287abb696d7584e6072ef',
      '0xa4e06dfef6096990bf06bcac76e929a97315aa17',
      '0xb10a1c5e933c3058391df750397430bc9f367c3e',
      '0xb28655907a470bd3574441cc8208581f55a533e1',
      '0x0107c5c8d9d7d333d6dbd8f3c000a09a608e203c',
      '0x9fe808d8a9e2e341ea30a8d5ac1e301c32b779fc',
      '0xfe173b71666d54d0341c9dac720c52ea2ccb33ca',
      '0x6def13e4d65214c51f36977a4f817a2507524a08',
      '0x6e8e5ef69ed663622f8291835f9a0562aac1ef1c',
      '0xa1f1e1bf54354bec7354c30698b4e970d89a0d11',
      '0x96e14345e79f4082c9212a11395485b21656d820',
      '0x3c320b2596b49848229b86f2063cd984f23ac96f',
      '0x5cbfd33637aa270c96af5414f5a6d33b1445d228',
      '0xc78bcaf6475446a3584ceb3a89c6cd19a9ede5cb',
      '0xc5a5df11d23052ef413c1388c3de106692ae5a72',
      '0x7da1176ee55a41b751c7613438220a3dcb060e8c',
      '0xad1d6178e0e38d27dcd984b93382744d3e4acc3c',
      '0x49b789c6a69bfb2ee8fa9d21fa9b3e9c8e689369',
      '0x5b530c1fa7d64b36e6bfa0467912ec2db334f992',
      '0x7bd184233b875bc55cc0ec1041457688d28b236e',
      '0x1b186ba094259f32af73652e1948f0cba061d291',
    ],
    keccak256,
    {
      hashLeaves: true,
      sortPairs: true,
    },
  );
};

export const connectWallet = async (setWallet) => {
  getBlockchainData(async (account, web3) => {
    setWallet(shortAddress(account));
  });
};

export const shortAddress = (_address) => {
  return _address.substr(0, 5) + '****' + _address.substr(_address.length - 4, _address.length);
};
