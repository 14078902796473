import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './App.css';
import Home from './pages';
import Mint from './pages/mint';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home className='App' />} />
        <Route path='/mint' element={<Mint />} />
      </Routes>
      {/* <Route path='/mint' element={<div></div>} /> */}
    </Router>
  );
}

export default App;
